import React, { useCallback, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import LocationSelect from '../../form/components/specialized/LocationSelect';
import Form from '../../form/components/Form';
import { RefPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import { CUSTOMER_RESOURCE } from '../../api/resources';
import TitleBar from '../../layout/components/TitleBar';
import InterestedMarketingTab from '../../interestedCustomer/components/tabs/InterestedMarketingTab';
import RadioInput from '../../form/components/RadioInput';
import ContactFormBody, { CONTACT_INITIAL_VALUES } from '../../contacts/components/ContactFormBody';
import TextInput from '../../form/components/TextInput';
import Checkbox from '../../form/components/Checkbox';
import { customerWithContactSchema } from '../schema';
import FormPrefiller from '../../form/components/FormPrefiller';
import { showCustomer, storeCustomer, updateCustomer } from '../customersSlice';
import { storeContact } from '../../contacts/contactsSlice';
import { useMailDialog } from '../../mails/hooks';
import { INFOMATERIAL_ENTITY } from '../../mailTemplates/entities';
import ConditionalField from '../../form/components/specialized/ConditionalField';
import NewMailTemplateSelect from '../../mails/components/send/NewMailTemplateSelect';
import { useCustomerReminderDate } from '../../interestedCustomer/hooks';
import LocationSuggestion from '../../locations/components/LocationSuggestion';
import { useUser } from '../../auth/hooks';
import { selectEmployeeById } from '../../employees/employeesSlice';
import { useSelector } from 'react-redux';
import Switch from '../../form/components/Switch';
import { selectAllLocations } from '../../locations/locationsSlice';
import { useGetUserEmplyeeId } from '../../abilities/hooks';
import { selectEmployeesById } from '../../employees/employeesSlice';

const NewCustomerForm = ({ onDone, submitRef }) => {
    const dispatch = useDispatch();
    const { addDaysToReminder } = useCustomerReminderDate();

    const currentUser = useUser();
    const currentEmployee = useSelector((state) =>
        selectEmployeeById(state, currentUser.employeeId)
    );

    const locations = useSelector(selectAllLocations);
    const empId = useGetUserEmplyeeId();
    const employees = useSelector(selectEmployeesById);
    const activeUserLocation = locations.filter(
        (location) => location.id === employees[empId].locationId
    );

    // only premium locations get selected premium customers
    const canWriteCustomerPremium = activeUserLocation[0].isPremium;

    //console.log("currentEmployee",currentEmployee.username, "location=",currentEmployee.locationId);
    const initialValues = useMemo(
        () => ({
            customer: {
                infomaterialSentAt: false,
                infomaterialSentVia: '',
                icZipcode: '',
                locationId: currentEmployee.locationId,
                contactedVia: '',
                contactedThroughOther: '',
                contactedThrough: [],
                contactedViaOther: '',
                recommendedBy: '',
            },
            contact: CONTACT_INITIAL_VALUES,
            mailTemplateId: '',
        }),
        [currentEmployee.locationId]
    );

    const { openMailDialog, handleDynamic } = useMailDialog();

    const calcReminder = useCallback(
        (values) => {
            if (!values.customer.infomaterialSentAt) {
                return new Date();
            }

            if (values.customer.infomaterialSentVia === 'post') {
                return addDaysToReminder(3);
            }

            return addDaysToReminder(2);
        },
        [addDaysToReminder]
    );

    const handleSubmit = useCallback(
        (values) => {
            let customer = {
                ...values.customer,
                infomaterialSentVia:
                    values.customer.infomaterialSentAt &&
                    values.customer.infomaterialSentVia === 'post'
                        ? ['post']
                        : [],
                infomaterialSentAt:
                    values.customer.infomaterialSentAt &&
                    values.customer.infomaterialSentVia === 'post' &&
                    currentEmployee.locationId !==
                        parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10)
                        ? new Date()
                        : null,
                currentType: { name: 'prospective' },
                receivedAt: new Date(),
                reminder: calcReminder(values),
            };
            const contact = {
                ...values.contact,
                isPrimaryContact: true,
            };

            return dispatch(storeCustomer(customer)).then(({ data }) => {
                return Promise.resolve(
                    dispatch(storeContact({ ...contact, customerId: data.id })).then(() =>
                        onDone(data.id)
                    )
                )
                    .then(() => {
                        /*
                        after creating a contact there should exist a cloud directory now
                        -> refresh the customer
                    */
                        return dispatch(showCustomer({ id: data.id }));
                    })
                    .then(() => {
                        if (
                            values.customer.infomaterialSentAt &&
                            values.customer.infomaterialSentVia === 'email'
                        ) {
                            openMailDialog({
                                entity: INFOMATERIAL_ENTITY,
                                entityId: data.id,
                                templateId: values.mailTemplateId,
                                onSent: () => {
                                    dispatch(
                                        updateCustomer({
                                            id: data.id,
                                            infomaterialSentAt: new Date(),
                                            infomaterialSentVia: ['email'],
                                        })
                                    );
                                },
                            });
                        }
                    });
            });
        },
        [dispatch, onDone, openMailDialog, calcReminder, currentEmployee]
    );

    const [formValues, setFormValues] = useState({});

    const handleChange = (e, v) => {
        setFormValues({ ...formValues, icZipcode: v });
    };

    return (
        <Form
            initialValues={initialValues}
            validationSchema={customerWithContactSchema}
            onSubmit={handleSubmit}
            subject={CUSTOMER_RESOURCE}
        >
            <FormPrefiller
                values={{
                    customer: {
                        infomaterialSentAt: true,
                        infomaterialSentVia: 'email',
                        icZipcode: '54321',
                        contactedVia: 'other',
                        contactedThrough: ['internet', 'radio', 'standort', 'empfehlung'],
                        contactedViaOther: 'Other',
                        recommendedBy: 'doctor',
                    },
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TitleBar>Stammdaten</TitleBar>
                    <ContactFormBody simple prefix="contact" />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TitleBar>Einsatzort</TitleBar>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        name="customer.icZipcode"
                                        label="PLZ Einsatzort"
                                        onChange={handleChange}
                                        maxLength="5"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <LocationSelect
                                        label="Standort"
                                        name="customer.locationId"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <LocationSuggestion
                                        label="Standort Vorschlag"
                                        fallback="Hamburg"
                                        zip={formValues.icZipcode}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TitleBar mt={3}>Marketing</TitleBar>
                            <InterestedMarketingTab prefix="customer" />
                        </Grid>
                        <Grid item xs={12}>
                            <TitleBar mt={3}>Infopaket</TitleBar>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Checkbox
                                        name="customer.infomaterialSentAt"
                                        label="Infopaket versenden"
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <RadioInput
                                        name="customer.infomaterialSentVia"
                                        translation="Select.sentVia"
                                        horizontal
                                        fullWidth
                                        noTurbo
                                    />
                                    <ConditionalField
                                        conditionFieldName="customer.infomaterialSentVia"
                                        condition="email"
                                    >
                                        <NewMailTemplateSelect
                                            name="mailTemplateId"
                                            label="Vorlage"
                                            entity={INFOMATERIAL_ENTITY}
                                            entityId={null}
                                            onDynamic={handleDynamic}
                                            fullWidth
                                        />
                                    </ConditionalField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TitleBar mt={3}>Premium</TitleBar>
                            <Grid item xs={12}>
                                <Switch
                                    disabled={!canWriteCustomerPremium}
                                    name="customer.isPremium"
                                    label="Premium Kunde"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} />
        </Form>
    );
};

NewCustomerForm.propTypes = {
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
};

NewCustomerForm.defaultProps = {
    submitRef: null,
};

export default NewCustomerForm;
